import {disableBodyScroll, enableBodyScroll} from "body-scroll-lock";

export const initDemoModal = () => {
    const section = document.querySelector('.connect-form-block');

    if (!section) return;

    const closeBtn = document.querySelector('.connect-form-close');

    if (!closeBtn) return;

    const firstInput = section.querySelector('.connect-form__fields input');

    const btns = [...document.querySelectorAll('.open-demo-btn')];

    if (btns.length === 0) return;

    btns.forEach(btn => {
        btn.addEventListener('click', () => {
           section.classList.add('is-modal');
           disableBodyScroll(section);

           if (firstInput) {
               firstInput.focus();
           }
        });
    });

    closeBtn.addEventListener('click', () => {
        section.classList.remove('is-modal');
        enableBodyScroll(section);
    })
}
