import './styles/main.scss';

import {initSliders} from "./js/sliders";
import {initOrganizers} from "./js/organizers";
import {initPresents} from "./js/presents";
import {initDemoModal} from "./js/demo-modal";
import {initPricesHeader} from "./js/prices-header";
import {initDirectionsMenu} from "./js/directions-menu";
import {connectForm} from "./js/connect-form";
import {LanguageSelector} from "./js/language-selector";
// import Handlebars from 'handlebars';

document.addEventListener('DOMContentLoaded', () => {
    initSliders();
    initOrganizers();
    initPresents();
    initDemoModal();
    initPricesHeader();
    initDirectionsMenu();
    connectForm();
    [...document.querySelectorAll('.language-selector')].map(item => new LanguageSelector(item));
    // const languageSelector = new LanguageSelector();
});


// Handlebars.registerHelper('testHelper', function () {
//     return 'test';
// })

console.log(FORM_URL);
