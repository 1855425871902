const sklonenie = (number, titles) => {
    if (titles.length < 3) {
        return titles[0];
    }

    let cases = [2, 0, 1, 1, 1, 2];
    return titles[ (number%100>4 && number%100<20)? 2 : cases[(number%10<5)?number%10:5] ];
}

export { sklonenie }
