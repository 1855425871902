const categoryBlocks = [...document.querySelectorAll('.plan-category-mobile-block')];
const planMobilePlans = [...document.querySelectorAll('.plan-mobile .plan')];

export const onPriceMoved = (newIndex, prevIndex, destIndex) => {
    if (!planMobilePlans[newIndex]) return;
    const id = planMobilePlans[newIndex].getAttribute('data-id');

    categoryBlocks.forEach(categoryBlock => {
        const categoryBlockId = categoryBlock.getAttribute('data-id');

        if (id == categoryBlockId) {
            categoryBlock.classList.add('is-active');
        }
        else {
            categoryBlock.classList.remove('is-active');
        }
    })

}
