import {globals} from "./globals";
import {sklonenie} from "./sklonenie";
import {getPointsLocale} from "./points-locale";

const getRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
}

class Present {
    constructor(item) {
        this.item = item;
        this.points = getRandomInt(50, 90); // parseInt(item.getAttribute('data-points') || '0')

        this.locale = getPointsLocale(item);

        const pointsTitle = item.querySelector('.points__text__title');

        if (pointsTitle) {
            pointsTitle.innerHTML = sklonenie(this.points, this.locale);
        }

        this.numberSpan = this.item.querySelector('.points__number');
        this.sumSpan = document.getElementById('points-sum');
        this.sumText = document.getElementById('points-text');

        this.item.addEventListener('click', this.onClick.bind(this));
    }

    onClick() {
        if (this.item.classList.contains('is-activated')) return '';

        const parent = this.item.parentNode;
        if (parent && parent.classList.contains('main__present')) {
            const title = parent.querySelector('.main__present-text');
            if (title) {
                title.style.opacity = '0';
                title.style.transform = 'scale(0)';
            }
        }

        this.item.classList.add('is-activated');
        globals.points += this.points;

        if (this.sumSpan) {
            this.sumSpan.innerText = globals.points;
            this.sumText.innerText = sklonenie(globals.points, this.locale)
        }

        this.animateNumber();
    }

    animateNumber() {
        if (!this.numberSpan) return;

        let counter = 0;

        const interval = setInterval(() => {
            this.numberSpan.innerText = counter;
            counter ++;

            if (counter > this.points) {
                clearInterval(interval);
                setTimeout(() => {
                    this.setDone();
                }, 1200);
            }
        }, 10);
    }

    setDone() {
        const onTransitionEnd = () => {
            this.item.removeEventListener('transitionend', onTransitionEnd);
            this.item.remove();
            this.clean();
        }
        this.item.addEventListener('transitionend', onTransitionEnd);
        this.item.classList.add('is-done');
        // setTimeout(() =>)
    }

    clean() {
        this.item = null;
        this.points = null;
        this.numberSpan =  null;
        this.sumSpan = null;
    }
}

export function initPresents() {
    const presents = [...document.querySelectorAll('.present')];
    presents.forEach(item => new Present(item));
}
