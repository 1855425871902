import {Splide} from "@splidejs/splide";
import {onPriceMoved} from "./slider-events";

const config = {
    'example-slider': {
        type: 'slide',
        gap: '20px',
        perPage: 3,
        perMove: 3,
        // padding: { right: '130px' },
        pagination: false,
        breakpoints: {
            1023: {
                perPage: 2,
                perMove: 2,
                // padding: { right: '50.819672131%'}
            },
            767: {
                perPage: 1,
                perMove:1,
                // padding: {right: 0}
            }
        }
    },
    'roi-nps-slider': {
        type: 'slide',
        gap: '20px',
        perPage: 3,
        perMove: 1,
        padding: { right: 0 },
        pagination: false,
        breakpoints: {
            1023: {
                perPage: 2,
                perMove: 2,
                // padding: { right: '50.8196721311%'}
            },
            767: {
                perPage: 1,
                padding: { right: 0 }
            }
        }
    },
    'plans-slider': {
        type: 'slide',
        perPage: 1
    }
}

const events = {
    'plans-slider': {
        'moved': onPriceMoved
    }
}

export const initSliders = () => {
    for (const [elementId, sliderConfig] of Object.entries(config)) {
        const slider = document.getElementById(elementId);

        if (!slider) continue;

        const instance = new Splide(slider, sliderConfig).mount();
        if (events && events[elementId]) {
            for (const [eventName, func] of Object.entries(events[elementId])) {
                instance.on(eventName, func.bind(instance));
            }
        }
    }
}
