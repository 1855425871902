export function Notification (selector) {
    this.element = document.querySelector(selector);

    if (!this.element) return;

    this.hideBtn = this.element.querySelector('.notification__close');

    this.show = () => {
        this.element.classList.add('is-shown');
        setTimeout(() => {
            this.element.classList.add('is-animated');
        }, 350);
    }

    this.hide = () => {
        this.element.classList.remove('is-animated');
        setTimeout(() => {
            this.element.classList.remove('is-shown');
        }, 350);
    }

    this.hideBtn.addEventListener('click', () => {
        this.hide();
    });
}
