import {clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll} from "body-scroll-lock";

export const initDirectionsMenu = () => {
    const menuContainer = document.querySelector('.directions-menu');

    if (!menuContainer) return;

    const hamburger = menuContainer.querySelector('.directions-menu__hamburger');

    if (!hamburger) return;

    const menuBlock = menuContainer.querySelector('.directions-menu__block');

    if (!menuBlock) return;

    const closeBtn = menuContainer.querySelector('.directions-menu__close');

    if (!closeBtn) return;

    const showClass = 'is-shown';
    const animationClass = 'is-animated';

    hamburger.addEventListener('click', () => {
        disableBodyScroll(menuBlock);
        menuBlock.classList.add(showClass);
        setTimeout(() => {
            menuBlock.classList.add(animationClass);
        });
    });

    closeBtn.addEventListener('click', () => {
        menuBlock.classList.remove(animationClass);
        setTimeout(() => {
            menuBlock.classList.remove(showClass);
            enableBodyScroll(menuBlock);
        }, 250);
    });
}
