export const initPricesHeader = () => {
    const el = document.querySelector(".plan-header")

    if (!el) return;

    window.onscroll = function() {myFunction()};

    const sticky = el.offsetTop;
    const gap = 100;

    function myFunction() {
        if ((window.pageYOffset - gap) > sticky) {
            el.classList.add("is-sticky");
        } else {
            el.classList.remove("is-sticky");
        }
    }
}